






















































import { Component, Prop, Vue } from 'vue-property-decorator';
// import PieChart from './Charts/PieChart.vue';
import { db } from '@/firebase';
import { UserModule } from '@/store/modules/user';

@Component({
  components: {
    // PieChart,
  },
})
export default class Overview extends Vue {
  @Prop({ default: '' }) collection!: string;
  @Prop({ default: false }) sections!: boolean;

  loading = true;
  ranks: any = [];
  series: any = [];
  procedures: any = [];
  tags: any = [];

  calculating = false;

  get isAdmin() {
    return UserModule.claims.Admin;
  }

  created() {
    db.collection('Overview')
      .doc(this.collection)
      .get()
      .then((snapshot) => {
        this.loading = false;
        const document: any = snapshot.data();
        if (document.captain) {
          this.ranks.push({ name: 'Captain', value: document.captain.total });
        }
        if (document.lieutenant) {
          this.ranks.push({ name: 'Lieutenant', value: document.lieutenant.total });
        }
        if (document.sergeant) {
          this.ranks.push({ name: 'Sergeant', value: document.sergeant.total });
        }
        if (document.none) {
          this.ranks.push({ name: 'None', value: document.none.total });
        }
        if (document.series) {
          this.series = Object.entries(document.series).map(([key, value]) => {
            return { value, name: key };
          });
        }
        if (document.procedures) {
          this.procedures = Object.entries(document.procedures).map(([key, value]) => {
            return { value, name: key };
          });
        }
        if (document.tags) {
          this.tags = Object.entries(document.tags).map(([key, value]) => {
            return { value, name: key };
          });
        }
      });
  }

  recalculateOverview() {
    this.calculating = true;
    db.collection('Overview')
      .doc(this.collection)
      .get()
      .then((overview: any) => {
        return overview.data();
      })
      .then((overviewData: any) => {
        return db
          .collection(this.collection)
          .get()
          .then((snapshot) => {
            const newSummary: any = {
              total: 0,
              series: {},
              procedures: {},
              tags: {},
              processed: [],
              next: overviewData.next,
            };
            snapshot.forEach((item: any) => {
              newSummary.processed.push(item.id);
              const itemData = item.data();
              const rank = itemData.rank || 'none';
              const series = itemData.series.length > 0 ? itemData.series : ['none'];
              const procedures = itemData.procedures;
              const tags = itemData.tags;
              if (series) {
                for (const iSeries of series) {
                  if (!newSummary[rank]) {
                    newSummary[rank] = {
                      total: 0,
                      series: {},
                      procedures: {},
                      tags: {},
                    };
                  }
                  if (!newSummary[rank].series[iSeries]) {
                    newSummary[rank].series[iSeries] = 0;
                  }
                  newSummary[rank].series[iSeries]++;
                  if (!newSummary.series[iSeries]) {
                    newSummary.series[iSeries] = 0;
                  }
                  newSummary.series[iSeries]++;
                }
              }
              if (procedures) {
                for (const procedure of procedures) {
                  if (!newSummary[rank].procedures[procedure]) {
                    newSummary[rank].procedures[procedure] = 0;
                  }
                  newSummary[rank].procedures[procedure]++;
                  if (!newSummary.procedures[procedure]) {
                    newSummary.procedures[procedure] = 0;
                  }
                  newSummary.procedures[procedure]++;
                }
              }
              if (tags) {
                for (const tag of tags) {
                  newSummary[rank].tags[tag]++;
                  if (!newSummary.tags[tag]) {
                    newSummary.tags[tag] = 0;
                  }
                  newSummary.tags[tag]++;
                }
              }
              newSummary[rank].total++;
              newSummary.total++;
            });
            return newSummary;
          });
      })
      .then((newSummary: any) => {
        console.info(newSummary);
        return db.collection('Overview').doc(this.collection).set(newSummary);
      })
      .catch((error) => console.error(error))
      .then(() => {
        this.calculating = false;
      });
  }
}
